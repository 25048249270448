.assinatura{
    text-align: right; 
    position: absolute; 
    bottom: 10px; 
    right: 10px;
}

.labelErro{
    color: red;
}

.codeStatus{
    font-size: 16px; text-align: center; font-weight: bold;
}

.fixHeightField{
    height: 84px;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .swiper {
    width: 100%;
    height: 100%;
    position:initial !important;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.botaoLink{
  color:black;
}

.imgGaleria{
  padding-bottom: 20px;
}